import { Text, useTheme, useBreakpointValue } from "@chakra-ui/react";

export const FeatureHeading = ({ children }) => {
  const theme = useTheme();  
  const textSize = useBreakpointValue(theme.fontSize.heading.sizes);
  return (
    <Text fontSize={textSize} color={theme.colors.lime[200]}>
      {children}
    </Text>
  );
};
