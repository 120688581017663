import { Heading } from "@/components/ui";

const MaintenancePage = () => (
  <div className="container-center">
    <div className="item-center tag">
      {`██████╗   ██████╗  ██╗   ██╗
██╔══██╗ ██╔═████╗ ╚██╗ ██╔╝
██║  ██║ ██║██╔██║  ╚████╔╝ 
██║  ██║ ████╔╝██║  ██╔═██╗ 
██████╔╝ ╚██████╔╝ ██╔╝  ██ 
╚═════╝   ╚═════╝  ╚═╝   ╚═╝
----------------------------`}
      <br />
      <div className="blinking-dot">
        <Heading>Offline... Check in later.</Heading>
      </div>
    </div>
  </div>
);

export default MaintenancePage;
