import { Text, useTheme, useBreakpointValue } from "@chakra-ui/react";

export const SubHeading = ({ size = 1, children, ...props }) => {
  const theme = useTheme();  
  const textSize = useBreakpointValue(theme.fontSize.subheading.sizes);
  return (
  <Text fontSize={textSize} color={theme.colors.light[300]}>
    {children}
  </Text>
  )
};
