import { defineStyle, defineStyleConfig, useTheme } from "@chakra-ui/react";

const baseStyle = defineStyle({
  borderRadius: 0, // disable the border radius
});

const sizes = {
  xl: defineStyle({
    fontSize: "xs",
  }),
};

const currentRole = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    borderRadius: "lg",
    border: "1",
    bg: `${c}.100`,
    _dark: {
      bg: `${c}.100`,
    },
    variant: "outline",
  };
});

export const badgeTheme = defineStyleConfig({
  baseStyle,
  sizes,
  variants: {
    script: {
      // border: '1px solid',
      // borderRadius: 'lg',
      // padding: '0.25rem 0.5rem',
      fontSize: "xs",
      borderColor: "primary.300",
      color: "primary.300",
    },
    enterprise: {
      // border: '1px solid',
      // borderRadius: 'lg',
      // padding: '0.25rem 0.5rem',
      fontSize: "xs",
      borderColor: "primary.500",
      color: "primary.500",
    },
    miscellaneous: {
      // border: '1px solid',
      // borderRadius: 'lg',
      // padding: '0.25rem 0.5rem',
      fontSize: "xs",
      borderColor: "secondary.300",
      color: "secondary.300",
    },
    self: {
      // border: '1px solid',
      // borderRadius: 'lg',
      // padding: '0.25rem 0.5rem',
      fontSize: "xs",
      borderColor: "secondary.300",
      color: "secondary.300",
    },
    "-2": {
      border: "1px solid",
      borderRadius: "lg",
      fontSize: "xs",
      borderColor: "secondary.400",
      color: "secondary.400",
    },
    "-1": {
      border: "1px solid",
      borderRadius: "lg",
      fontSize: "xs",
      borderColor: "secondary.300",
      color: "secondary.300",
    },
    "0": {
      border: "1px solid",
      borderRadius: "lg",
      fontSize: "xs",
      borderColor: "primary.100",
      color: "primary.100",
    },
    "1": {
      border: "1px solid",
      borderRadius: "lg",
      fontSize: "xs",
      borderColor: "primary.300",
      color: "primary.300",
    },
    "2": {
      border: "1px solid",
      borderRadius: "lg",
      fontSize: "xs",
      borderColor: "primary.500",
      color: "primary.500",
    },
    "3": {
      border: "1px solid",
      borderRadius: "lg",
      fontSize: "xs",
      borderColor: "primary.500",
      color: "primary.500",
    },
    current: currentRole,
  },
  defaultProps: {
    colorScheme: "primary", // set the default color scheme to green
  },
});
