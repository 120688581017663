export const LoadingDots = ({ size = 1 }) => {
  const H = `h${size}`;

  return (
    <>
      {" "}
      <span className="blinking-dot">.</span>
      <span className="blinking-dot">.</span>
      <span className="blinking-dot">.</span>
    </>
  );
};
