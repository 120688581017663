import { Text } from "@chakra-ui/react";

export const SudonymHeading = ({ size = 1 }) => {
  const H = `h${size}`;

  return (
    <Text fontSize="xl" className="lime">
      {`>_sudonym`}
    </Text>
  );
};
