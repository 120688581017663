import {   
  Box,
  useTheme,
  Text,
  useBreakpointValue, 
} from "@chakra-ui/react";

export const Footer = ({ size = 1 }) => {
  const theme = useTheme();
  const fontSize = useBreakpointValue(theme.fontSize.cli.sizes)

  return (
    <Box as="footer" textAlign="center" w="65vw" position="fixed" bottom="0" padding="1rem">
      <Text fontSize={fontSize}>
        Powered by <a href="https://meshjs.dev/">MeshJS</a> on Cardano | © 2024
        Expression Labs
      </Text>
    </Box>
  );
};
