import React, {useEffect} from "react";
import "@/styles/global.css";
import Head from "next/head";
import { MeshProvider } from "@meshsdk/react";
import { SessionProvider } from 'next-auth/react';

import MaintenancePage from "../components/UnderMaintenance";

import { ChakraProvider, Box } from "@chakra-ui/react";
import theme from "@/styles/theme";

const App = ({ Component, pageProps, err }) => {
  return (
    <>
      {process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "false" ? (
        <Box>
          <Head>
            <title>d0x</title>            
            <link rel="shortcut icon" href="/favicon.ico" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <meta name="apple-mobile-web-app-capable" content="yes"></meta>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
          </Head>
          
          <MeshProvider>
            <SessionProvider session={pageProps.session}>
              <ChakraProvider theme={theme}>
                <Component {...pageProps} err={err} />
              </ChakraProvider>
            </SessionProvider>
          </MeshProvider>
        </Box>
      ) : (
        <MaintenancePage />
      )}
    </>
  );
};

export default App;
