import { extendTheme, defineStyleConfig } from "@chakra-ui/react";
import { badgeTheme, popoverTheme } from "@/theme/components";
import { fontStyle } from "html2canvas/dist/types/css/property-descriptors/font-style";
// import "./global.css";

// const breakpoints = {
//   sm: "440px",
//   md: "768px",
//   lg: "960px",
//   xl: "1200px",
//   "2xl": "1536px",
// };

const theme = extendTheme({
  breakpoints: { base: '31em', sm: '45em', md:'80em', lg:'150em'},

  styles: {
    global: (props) => ({
      // 'body': {
        
      //   fontFamily: "'InputMono",
      //   fontWeight: "light"
      // }
      // body: {
      //   color: "light.100",
      // },
    }),
  },
  config: {
    initialColorMode: "crt",
    useSystemColorMode: false,
    defaultColorMode: "crt",
  },
  fonts: {
    heading: "'InputMono', monospace",
    body: "'InputMono', monospace",
    block: "'437', monospace",
  },
  fontSize: {
    heading: {
      fontWeight: "600",
      sizes: {
        base: "1rem",
        sm: "1.1rem",
        md: "1.2rem",
        lg: "1.3rem",
      },
    },
    subheading: {
      sizes: {
        base: "1rem",
        sm: "1.1rem",
        md: "1.1rem",
        lg: "1rem",
      },
    },
    body: {
      sizes: {
        base: ".8rem",
        sm: ".8rem",
        md: ".8rem",
        lg: ".8rem",
      },
    },
    cli: {
      sizes: {
        base: ".65rem",
        sm: ".8rem",
        md: "1rem",
        lg: "1rem",
      },
      padding: {
        base: ".6rem 0",
        sm: ".6rem 0",
        md: ".7rem 0",
        lg: ".7rem 0",
      },
      lineHeight: {
        base: ".8rem",
        sm: "1rem",
        md: "1.2rem",
        lg: "1.2rem",
      },
    },
    footer: {
      sizes: {
        base: "0.7rem",
        sm: "0.7rem",
        md: "0.7rem",
        lg: "0.7rem",
      },
    },
  },
  colors: {
    dark: {
      100: "#485464",
      300: "#354150",
      500: "#262f3a",
      700: "#1d242c",
      900: "#181e25",
    },
    light: {
      100: " #f0e8d5", //offwhite
      200: " #ebdbb2", //offwhite
      300: " #FACC15", //yellow
    },
    lime: {
      200: "#b2ff7e", //lime
      400: "#688a51", //dark lime
    },
    purple: "#7288da", //purple
    orange: "#F97316",
    red: "#EF4444",
  },
  buttonSize: { base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' },
  components: {
    Badge: badgeTheme,
    Popover: popoverTheme,
    Input: {
      defaultProps: {
        variant: null
      },
      sizes: {
        md: {
          field: {
            borderRadius: "0",
          },
          addon: {
            borderRadius: "0",
          }
        }
      },
      baseStyle: {
        field: {
          color: "light.200",
          bg: "dark.700",
          border: "1px solid",
          borderColor: " lime.400",
          _focus: {
            borderColor: "lime.200",
            boxShadow: "none",
          },
        }
      },
    },
    Button: {
      baseStyle: {
        fontSize: "1rem",
        fontWeight: "400",
      },
      variants: {
        home_button: {
          fontWeight: "600",
          color: "lime.200",
          _hover: {
            bg: "none",
            color: "orange",
            textDecoration: "none",
          },
        },
        tool_button: {
          color: "light.200",
          bg: "dark.300",
          _hover: {
            bg: "dark.100",
            color: "lime.200",
            textDecoration: "none",
          },
        },
        standard_button: {
          width: "100%",
          color: "dark.900",
          bg: "lime.200",
          outline: "none",
          borderRadius: "0",
          _hover: {
            bg: "dark.700",
            color: "lime.200",
            textDecoration: "none",
          },
          _disabled: {
            bg: "lime.200",
            color: "dark.700",
            opacity: "50%",
          }
        },
        shiny_button: {
          width: "100%",
          color: "dark.900",
          bg: "light.300",
          outline: "none",
          borderRadius: "0",
          _hover: {
            bg: "dark.700",
            color: "lime.200",
            textDecoration: "none",
          },
          _disabled: {
            bg: "lime.200",
            color: "dark.700",
            opacity: "50%",
          }
        },
        toggle_button: {
          width: "100%",
          bg: "dark.500",
          color: "lime.200",
          outline: "none",
          borderRadius: "0",
          _active: {
            color: "dark.900",
            bg: "lime.200",
            opacity: "100%",
          },
          _hover: {
            textDecoration: "none",
            color: "dark.900",
            bg: "lime.400",
          },
          _disabled: {
            bg: "red",
            color: "dark.700",
            opacity: "50%",
          },
        },
        tiny_button: {
          bg: "none",
          color: "orange",
          outline: "none",
          borderRadius: "0",
          _hover: {
            bg: "none",
            color: "lime.200",
            textDecoration: "none",
          },
          _disabled: {
            color: "dark.500",
            opacity: "50%",
          }
        },
        canvas_tab_button: {
          color: "lime.200",
          bg: "dark.900",
          outline: "none",
          borderRadius: "0",
          _hover: {
            bg: "lime.200",
            color: "dark.900",
            textDecoration: "none",
          },
        },
        canvas_tab: {
          color: "dark.900",
          bg: "lime.400",
          outline: "none",
          borderRadius: "0",
          _hover: {
            bg: "dark.900",
            color: "lime.200",
            textDecoration: "none",
          },
          _disabled: {
            bg: "lime.200",
            color: "dark.900",
            opacity: "100%",
            cursor: "pointer",
          }
        },
        palette: {
          fontFamily: "'437', monospace",
          color: "lime.400",
          p: "0",
          outline: "none",
          borderRadius: "0",
          _hover: {
            bg: "dark.900",
            color: "lime.200",
            textDecoration: "none",
          },
          _disabled: {
            color: "lime.200",
            opacity: "100%",
            cursor: "pointer",
          }
        },
        palette_brush: {
          color: "dark.900",
          bg: "lime.200",
          outline: "none",
          borderRadius: "0",
          textDecoration: "none",
          _hover: {
            bg: "dark.900",
            color: "lime.200",
          },
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          background: "dark.900",
          borderColor: "dark.900"
        },
        icon: {
          color: "lime.200"
        }
      }
    },
    Tab: {
      baseStyle: {
        tab: {
          fontWeight: "600",
        },
        tablist: {
          
          fontWeight: "600",
          color: "lime.200",
          bg: "dark.700",
          _selected: {
            color: "dark.700",
            bg: "lime.100",
          },
          _hover: {
            textDecoration: "none",
            color: "dark.700",
            bg: "lime.100",
          },
        },
      },
      variants: {
        canvas_tab: {
          color: "lime.200",
          outline: "none",
          borderRadius: "0",
          _hover: {
            textDecoration: "none",
            bg: "lime.200",
            color: "dark.900",
          },
        },
      },
      // _selected: {
      //   bg: "#FFFFFF",
      // },
    },
    Accordion: {
      baseStyle:{
        root: {
          borderColor: 'dark.500',
        },
        panel: {
        //   color: "lime.200",
        //   border: '1px solid',
        //   borderColor: 'gray.600',
        //   background: 'gray.800',
        },
        button: {
          color: "lime.200",
          _hover: {
            textDecoration: "none",
            bg: "lime.200",
            color: "dark.900",
          },
        }
        // icon: {
        //   border: '1px solid',            
        //   borderColor: 'gray.600',
        //   background: 'gray.600',
        //   color: 'gray.400',
        // },
      },
    },
    Textarea: {
      variants: {
        canvas: {
          fontFamily: "'437', monospace",
          // fontSize: "0.8rem",
          cursor: "pointer",
          color: "lime.200",
          lineHeight: "100%",
          whiteSpace: "pre-wrap",
          bg: "rgba(0, 0, 0, 0)",
          border: "1px solid",
          borderColor: "dark.900",
          padding: "2px 10px",
          transition: "0.2s",
          outline: "none",
          borderRadius: "0",
          _focus: {
            borderColor: "lime.200",
          },          
          _selection: {
            backgroundColor: "red", // Set the background color of the caret
            color: "white",          // Set the text color of the caret
          },
        },
        // baseStyle: {
        //   bg: "#FFFFFF",
        //   border: "1px solid",
        //   borderColor: "gray.300",
        //   _focus: {
        //     borderColor: "blue.500",
        //   },
        // },
      },
    },
    Alert: {
      baseStyle:{
      },
      variants: {
        solid: () => {
          return {
            icon: {
              padding: '0.1rem',
              color: 'dark.700',
            },
            container: {
              marginBottom: '1rem',
              color: 'dark.700',
              bg: 'lime.200',
            },
            spinner: {
              color: 'dark.700',
            },
          }
        }
      }
    },
    Text: {
      baseStyle: {
        fontFamily: "'InputMono', monospace",
        color: 'light.200'
      },
      sizes: {
        base: { fontSize: 'xs' },
        sm: { fontSize: 'sm' },
        md: { fontSize: 'md' },
        lg: { fontSize: 'lg' },
      },
    },
    Menu: {
      baseStyle: {
        button: {
          // this will style the MenuButton component
          bg: 'teal.500',
          color: 'gray.200',
          _hover: {
            bg: 'teal.600',
            color: 'white',
          },
        },
        list: {
          color: "lime.200",
          bg: "dark.900",
          outline: "none",
          borderRadius: "0",
        },
        item: {
          
          color: "lime.200",
          bg: "dark.900",
          outline: "none",
          borderRadius: "0",
          // this will style the MenuItem and MenuItemOption components
          // color: 'gray.200',
          _hover: {
            color: "lime.200",
            bg: 'dark.500',
          },
          _focus: {
            color: "lime.200",
            bg: 'dark.500',
          },
        },
        // groupTitle: {
        //   // this will style the text defined by the title prop
        //   // in the MenuGroup and MenuOptionGroup components
        //   textTransform: 'uppercase',
        //   color: 'white',
        //   textAlign: 'center',
        //   letterSpacing: 'wider',
        //   opacity: '0.7',
        // },
        // command: {
        //   // this will style the text defined by the command
        //   // prop in the MenuItem and MenuItemOption components
        //   opacity: '0.8',
        //   fontFamily: 'mono',
        //   fontSize: 'sm',
        //   letterSpacing: 'tighter',
        //   pl: '4',
        // },
        // divider: {
        //   // this will style the MenuDivider component
        //   my: '4',
        //   borderColor: 'white',
        //   borderBottom: '2px dotted',
        // },
      },
    },
    Tabs: {
      // Define styles for the default variant
      baseStyle: {
        tab: {
          bg: "lime.400",
          _selected: {
            bg: "lime.200",
            color: "dark.900",
          },
          _hover: {
            bg: "dark.900",
            color: "lime.200",
            textDecoration: "none",
          },
        },
        // Define custom styles for the "customVariant" variant
      }
    },
    NumberInput: {
      baseStyle:{
        root:{
          color: "lime.200",
          bg: "dark.700",
        },
        field: {
          padding: "0.15rem 0.5rem",
          color: "light.200",
          border: "1px solid",
          borderColor: " lime.400",
          _focus: {
            borderColor: "lime.200",
            boxShadow: "none",
          },
          focusBorderColor: "lime.200",
        }
      },
    }
  },
});

export default theme;
